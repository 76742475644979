.mic {  
    /* position: absolute; */
    /* top: 38vh;
    left: 47.7vw; */
    /* transform: translate(-50%, -50%); */
    z-index: 0; 
      color: #fff; 
    }  
    /* .mic::before .mic::after {  
      content: '';  
      position: absolute;  
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); 
      border-radius: 100%;  
      z-index: 2;  
      box-shadow: 0 0 20px 20px #1c084f;
    }
    .mic::before{
      width: 400px;
      height: 400px;
      background-color: #1a084e;
    }
    .mic::after{
      width: 250px;
      height: 250px;
      background-color: #2f1e5f;
      animation: circle-size 0.8s linear infinite alternate;
    } */
    /* .mic-icon {
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(5);
      display: black;
      width: 16px;
      border-bottom-left-radius: 120px;
      border-bottom-right-radius: 120px;
      border: 2px solid;
      border-top: 0;
      margin-top: 20px;
      z-index: 3;
    }
    .mic-icon::before .mic-icon::after {  
      content: '';  
      display: block;
      box-sizing: border-box;
      position: absolute;  
     
    }
    .mic-icon::before {
      width: 2px;
      height: 5px;
      top: calc(100% + 1px);
      left: 50%;
      transform: translate(-50%, 0);
      background-color: #fff;
    }
   .mic-icon::after {
      border: 2px solid;
      width: 8px;
      height: 18px;
      left: 50%;
      top: -10%;
      transform: translate(-50%, 0);
      border-radius: 4px;
    } */
    .mic-shadow {
      position: absolute;
      /* top: 0px;
      left: 40%; */
      width: 100px;
      height: 100px;
      border-radius: 100%;
      z-index: 1;
      box-shadow: -5px -5px 20px 5px #A6E0B9, 14px -10px 47px 10px #60C463, -11px -15px 77px 10px #2F996E, 21px 5px 17px 10px #1b7d8f, 3px 2px 57px 10px #003333;
      animation: shadow-rotate 1.5s linear infinite, fade-in 1s ease-out forwards;
      transform-origin: center;
    }
    @keyframes fade-in {
      from { opacity: 0; }
      to { opacity: 1; }
    }
    
    @keyframes circle-size {
      from{
        width: 250px;
        height: 250px;
      }
      to{
        width: 300px;
        height: 300px;
      }
    }
    @keyframes shadow-rotate {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
    .mic-loading {
      position: absolute;
      width: 100px;
      height: 100px;
      border-radius: 100%;
      z-index: 1;
      box-shadow: -5px -5px 20px 5px #469761, 14px -10px 47px 10px #60C463, -11px -15px 77px 10px #2F996E, 21px 5px 17px 10px #052c2c, 3px 2px 57px 10px #003333;
      animation: loading-circle-size 1.5s ease-in-out infinite, loading-fade-in 1s ease-out forwards;
      transform-origin: center;
    }
    
    @keyframes loading-circle-size {
      0%, 100% {
        transform: scale(.7);
      }
      50% {
        transform: scale(.8); /* Adjust scale value to control the size of the pulse */
      }
    }
    
    @keyframes loading-fade-in {
      from { opacity: 0; }
      to { opacity: 1; }
    }
    
.HeaderContainer {
    display: flex;
    justify-content: space-between;
}

.InnerHeaderLeft {
    display: flex; 
    justify-content: flex-start;
    align-items: center;
    width: auto;
}

.InnerHeaderRight {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: auto;
    margin-right: 20px;
    gap: 15px;
}
.messageContainer {
    flex: 1;
    padding-right: 20px;
}
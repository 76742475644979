.ChatHistoryComponent {  
    position: relative;  
    display: flex;  
    align-items: center;  
    justify-content: space-between;  
}  
  
.editInput {  
    flex: 1;  
    display: flex;  
    align-items: center;  
}  
  
.editButtonGroup {  
    display: flex;  
    gap: 4px;  
    position: absolute;  
    right: 0;  
    top: 0;  
    bottom: 0;  
    display: flex;  
    align-items: center;  
    padding: 0 0 0 76px;  
}  
  
.hoverActions {  
    position: absolute;  
    right: 0;  
    top: 0;  
    bottom: 0;  
    display: flex;  
    align-items: center;  
    padding: 0 8px 0 76px;  
    /* background: linear-gradient(to right, rgba(46, 46, 46, 0), rgba(46, 46, 46, 1), rgba(34, 34, 34, 1));   */
    /* background: linear-gradient(to right, var(--colorNeutralBackground1), var(--colorNeutralBackground2), var(--colorNeutralBackground3)); */
    background: linear-gradient(to right, transparent, 30%, var(--colorNeutralBackground1), var(--colorNeutralBackground1));  
    
}  



@keyframes pulse {
    0% {
      border-color: #A6E0B9;
      box-shadow: inset 0 0 10px rgba(166, 224, 185, 0.5); /* Lighter inner shadow */
    }
    50% {
      border-color: #2F996E;
      box-shadow: inset 0 0 0px rgba(47, 153, 110, 0.5); /* Adjusted to match border color at 50% */
    }
    100% {
      border-color: #A6E0B9;
      box-shadow: inset 0 0 10px rgba(166, 224, 185, 0.5); /* Back to initial state */
    }
  }
  
  
  .pulsingBorder {
    animation: pulse 2s infinite;
    border-style: solid;
    border-width: 5px 5px 5px 5px; /* Top, Right, Bottom, Left */
    border-color: #00ff00; /* Initial border color */
    box-shadow: inset 0 0 10px rgba(0, 255, 0, 0.5); /* Initial lighter inner shadow */
  
  }
  
.SkillCardContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    padding-top: 15px;
    align-self: center;
    justify-content: flex-start;
    margin-right: 10px;
}

.ThreadSettingsChatHistory {
    padding-top: 15px;
    padding-bottom: 10px;
    justify-self: start;
}

.ThreadSettingsSkillsHeader {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.SkillCard {
    display: grid;
    height: min-content;
    grid-template-columns: 1fr;
    /* grid-template-rows: 0.7fr 1.6fr 0.7fr;  */

    grid-template-areas:
        'CardHeader'
        'CardBody'
        'CardFooter';
}

.CardHeader {
    grid-area: CardHeader;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.CardTitle {
    display: flex;
    align-items: center;
    gap: 5px;
}

.CardBody {
    grid-area: CardBody;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.CardBody > :first-child {
    align-self: start;
}

.CardFooter {
    grid-area: CardFooter;
    display: flex;
    flex-direction: column;
}

.ChildSkillContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.custom-scrollbar-container::-webkit-scrollbar {
    width: 10px;
    margin-left: 5px;
}

.custom-scrollbar-container::-webkit-scrollbar-track {
    background: transparent;
}

.custom-scrollbar-container::-webkit-scrollbar-thumb {
    background: var(--colorNeutralBackground1);
    border-radius: 5px;
}

.custom-scrollbar-container::-webkit-scrollbar-thumb:hover {
    background: var(--colorNeutralBackground2Hover);
}

.custom-scrollbar::-webkit-scrollbar {
    width: 10px;
}
.custom-scrollbar::-webkit-scrollbar-track {
    background: transparent;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
    background: var(--colorNeutralBackground1);
    border-radius: 5px;
}
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: var(--colorNeutralBackground2Hover);
}

.Erm-custom-scrollbar::-webkit-scrollbar {
    width: 10px;
}
.Erm-custom-scrollbar::-webkit-scrollbar-track {
    background: transparent;
}
.Erm-custom-scrollbar::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 5px;
}
.Erm-custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: var(--colorNeutralBackground2Hover);
}
.StarterPromptCardBody {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 30px;
    height: 100%;
}

.StarterPromptCardBody:first-child {
    margin-bottom: auto;
}

.StarterPromptCardBody:last-child {
    margin-top: auto;
}

.SelectedFiles {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    max-width: 100%;
    overflow-x: auto;
    padding-top: 16px;
}

.textarea-container {  
    position: relative;  
    width: 100%;  
    height: 300px; /* Total height of the component */  
    overflow: hidden; /* Hide the overflow */  
  }  
    
  .scrollable-wrapper {  
    overflow-y: auto; /* Enable scrolling within this wrapper */  
    height: 100px; /* Height for the scrollable area */  
  }  
    
  .textarea-container textarea {  
    width: 100%;  
    height: 100%; /* Fill the scrollable wrapper */  
    border: none;  
    padding: 8px;  
    box-sizing: border-box;  
    resize: none; /* Disable resizing */  
  }  
  .textarea-container textarea::-webkit-scrollbar {
      width: 8px;   /* Width of the scrollbar */
  }
  
  .textarea-container textarea::-webkit-scrollbar-track {
      background-color: transparent; /* Background color of the scrollbar track */
  }
  
  .textarea-container textarea::-webkit-scrollbar-thumb {
      background-color: var(--colorNeutralBackground2Hover); 
      border-radius: 5px;  
  }
  
  .textarea-container textarea::-webkit-scrollbar-thumb:hover {
      background-color: var(--colorNeutralBackground3Hover); 
  }
.SettingsMenuContainer {
    display: grid;
    grid-template-columns: 0.7fr 2.3fr;
    grid-template-rows: 0.2fr 1.8fr 0.2fr;
    gap: 0px 0px;
    min-height: 100%;
    grid-template-areas:
        'TopNav TopNav'
        'MainNav MainNav'
        'FooterNav FooterNav';
}

@media (max-width: 600px) {
    .SettingsMenuContainer {
        grid-template-rows: 0.2fr 1.9fr 0.1fr;
    }
}

.SettingsMenuLeftNav {
    grid-area: LeftNav;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 0px;
    padding-top: 0px;
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.SettingsMenuTopNav {
    grid-area: TopNav;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px 20px 25px;
}

.SettingsMenuTopNav > *:last-child {
    margin-left: auto;
    align-self: flex-start;
}

.SettingsMenuFooterNav {
    grid-area: FooterNav;
    justify-self: end;
    align-self: center;
    padding-right: 25px;
}

.SettingsMenuAppearance {
    grid-area: MainNav;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    grid-auto-rows: auto;
    align-content: start;
    padding-right: 25px;
}

.SettingsMenuBottomNav {
    grid-area: BottomNav;
    align-self: end;
    justify-self: end;
    padding-bottom: 5px;
    padding-right: 10px;
}

.SettingsMenuAppearance > *:nth-child(3n + 1) {
    justify-self: start;
    align-self: center;
}

.SettingsMenuAppearance > *:nth-child(3n + 2) {
    justify-self: end;
    padding-right: 12px;
    align-self: center;
}

.SettingsMenuAppearance > *:nth-child(3n + 3) {
    grid-column: span 2;
    align-self: start;
}

.SettingsMenuDefaults {
    grid-area: MainNav;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    grid-auto-rows: auto;
    align-content: start;
    overflow-y: auto;
    padding-left: 25px;
    padding-right: 25px;
    overflow: hidden;
}

.SettingsMenuDefaults > *:nth-child(3n + 1) {
    justify-self: start;
    align-self: center;
}

.SettingsMenuDefaults > *:nth-child(3n + 2) {
    justify-self: end;
    align-self: center;
}

.SettingsMenuDefaults > *:nth-child(3n + 3) {
    grid-column: span 2;
    align-self: start;
}

.SettingsMenuDefaults > *:last-child {
    grid-column: span 2;
    padding-top: 15px;
}

.SettingsMenuSkillCardsFlex {
    display: flex;
    flex: 0 0 50px;
    flex-wrap: wrap;
    align-items: top;
    gap: 10px;
}

@media screen and (max-width: 600px) {
    .SettingsMenuSkillCardsFlex {
        margin: auto;
        width: 60%;
    }
}

.SettingsMenuChatHistoryFlex {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.ClearThreadsDialogContent {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
